<template>
  <div>
    <div class="card card-hover page-item-in-schedule-list">
      <div class="page-showing-status">
        <span
          v-tooltip.top-center="{
            content: `${pageStatusTextForTooltip}`,
            html: true
          }"
          class="ml-auto"
          :class="`page-showing--${pageStatusClass}`"
        >
          <i class="fa fa-circle" />
        </span>
      </div>
      <div class="card-header d-flex align-items-center">
        <div class="mr-1" role="button" @click="toggleShowDetails">
          <i v-if="showDetails" class="icon-arrow-down"></i>
          <i v-else class="icon-arrow-right"></i>
        </div>
        <div>
          <h6 class="break-all" role="button" @click="clickOnPageName">
            {{ schedule.injected.pageName }}
            <!--        <span class="ml-auto">-->
            <!--          <i class="fa fa-circle"/>-->
            <!--          показывается-->
            <!--        </span>-->
          </h6>
          <div class="text-left">
            <p class="card-text page-schedule-range">
              {{ schedule.schedule.start | moment }} — {{ (schedule.schedule.stop - 1) | moment }}
            </p>
          </div>
        </div>
      </div>

      <div
        v-if="showDetails"
        class="card-block text-left card-block-in-pages-schedule d-flex flex-column align-items-center animated fadeIn"
      >
        <div class="d-flex flex-wrap days-of-week">
          <div :class="{ enabled: schedule.injected.daysOfWeekForShowing.monday === true }">
            {{ $t('general.mo') }}
          </div>
          <div :class="{ enabled: schedule.injected.daysOfWeekForShowing.tuesday }">
            {{ $t('general.tu') }}
          </div>
          <div :class="{ enabled: schedule.injected.daysOfWeekForShowing.wednesday }">
            {{ $t('general.we') }}
          </div>
          <div :class="{ enabled: schedule.injected.daysOfWeekForShowing.thursday }">
            {{ $t('general.th') }}
          </div>
          <div :class="{ enabled: schedule.injected.daysOfWeekForShowing.friday }">
            {{ $t('general.fr') }}
          </div>
          <div :class="{ enabled: schedule.injected.daysOfWeekForShowing.saturday }">
            {{ $t('general.sa') }}
          </div>
          <div :class="{ enabled: schedule.injected.daysOfWeekForShowing.sunday }">
            {{ $t('general.su') }}
          </div>
        </div>
        <div class="mb-1 text-left">
          <div class="text-center" v-if="isAllDay === true">
            <span
              v-tooltip.top-center="{
                content: `${hourIntervalsForTooltip}`,
                html: true
              }"
            >
              {{ $t('pageprofile.showHours') }}:
            </span>
            <span role="button" @click="toggleShowHours">
              <b>{{ $t('pageprofile.showHoursAllDay') }}</b>
              <i v-if="showHours" class="icon-arrow-down icon-small-in-pages-schedule"></i>
              <i v-else class="icon-arrow-left icon-small-in-pages-schedule"></i>
            </span>
          </div>
          <div class="text-center" v-else>
            <span
              v-tooltip.top-center="{
                content: `${hourIntervalsForTooltip}`,
                html: true
              }"
            >
              {{ $t('pageprofile.showHours') }}:
            </span>
            <span role="button" @click="toggleShowHours">
              <b>{{ $t('pageprofile.showHoursNotAllDay') }}</b>
              <i v-if="showHours" class="icon-arrow-down icon-small-in-pages-schedule"></i>
              <i v-else class="icon-arrow-left icon-small-in-pages-schedule"></i>
            </span>
            <div></div>
          </div>
          <div v-if="showHours" class="d-flex justify-content-center text-center animated fadeIn">
            <div v-if="showHours" class="d-flex flex-wrap hours-of-day">
              <div :class="{ enabled: schedule.injected.hoursForShowing[0] === true }">
                0
              </div>
              <div :class="{ enabled: schedule.injected.hoursForShowing[1] === true }">
                1
              </div>
              <div :class="{ enabled: schedule.injected.hoursForShowing[2] === true }">
                2
              </div>
              <div :class="{ enabled: schedule.injected.hoursForShowing[3] === true }">
                3
              </div>
              <div :class="{ enabled: schedule.injected.hoursForShowing[4] === true }">
                4
              </div>
              <div :class="{ enabled: schedule.injected.hoursForShowing[5] === true }">
                5
              </div>
              <div :class="{ enabled: schedule.injected.hoursForShowing[6] === true }">
                6
              </div>
              <div :class="{ enabled: schedule.injected.hoursForShowing[7] === true }">
                7
              </div>
              <div :class="{ enabled: schedule.injected.hoursForShowing[8] === true }">
                8
              </div>
              <div :class="{ enabled: schedule.injected.hoursForShowing[9] === true }">
                9
              </div>
              <div :class="{ enabled: schedule.injected.hoursForShowing[10] === true }">
                10
              </div>
              <div :class="{ enabled: schedule.injected.hoursForShowing[11] === true }">
                11
              </div>
              <div :class="{ enabled: schedule.injected.hoursForShowing[12] === true }">
                12
              </div>
              <div :class="{ enabled: schedule.injected.hoursForShowing[13] === true }">
                13
              </div>
              <div :class="{ enabled: schedule.injected.hoursForShowing[14] === true }">
                14
              </div>
              <div :class="{ enabled: schedule.injected.hoursForShowing[15] === true }">
                15
              </div>
              <div :class="{ enabled: schedule.injected.hoursForShowing[16] === true }">
                16
              </div>
              <div :class="{ enabled: schedule.injected.hoursForShowing[17] === true }">
                17
              </div>
              <div :class="{ enabled: schedule.injected.hoursForShowing[18] === true }">
                18
              </div>
              <div :class="{ enabled: schedule.injected.hoursForShowing[19] === true }">
                19
              </div>
              <div :class="{ enabled: schedule.injected.hoursForShowing[20] === true }">
                20
              </div>
              <div :class="{ enabled: schedule.injected.hoursForShowing[21] === true }">
                21
              </div>
              <div :class="{ enabled: schedule.injected.hoursForShowing[22] === true }">
                22
              </div>
              <div :class="{ enabled: schedule.injected.hoursForShowing[23] === true }">
                23
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageInScheduleList',
  props: ['inSchedule'],
  data() {
    return {
      showHours: false,
      showDetails: false
    };
  },
  computed: {
    schedule() {
      return this.inSchedule;
    },
    isAllDay() {
      for (const hourIndex in this.schedule.injected.hoursForShowing) {
        if (this.schedule.injected.hoursForShowing[hourIndex] === false) {
          return false;
        }
      }
      return true;
    },
    pageStatusClass() {
      if (this.schedule.injected.isShowingInSelectedDayOfWeek === true) {
        return 'active-and-day-match';
      }
      return 'active-but-day-not-match';

      return 'active-and-day-match';
    },
    pageStatusTextForTooltip() {
      if (this.schedule.injected.isShowingInSelectedDayOfWeek === true) {
        return this.$t('pageprofile.pageScheduleStatusTextForTooltipAciveAndDayMatch');
      }
      return this.$t('pageprofile.pageScheduleStatusTextForTooltipAciveAndDayNotMatch');

      return '';
    },
    hourIntervalsForTooltip() {
      let resultString = '';

      if (this.schedule.injected.hoursForShowing[0] === true) {
        resultString += '00:00 - 01:00 <br>';
      }
      if (this.schedule.injected.hoursForShowing[1] === true) {
        resultString += '01:00 - 02:00 <br>';
      }
      if (this.schedule.injected.hoursForShowing[2] === true) {
        resultString += '02:00 - 03:00 <br>';
      }
      if (this.schedule.injected.hoursForShowing[3] === true) {
        resultString += '03:00 - 04:00 <br>';
      }
      if (this.schedule.injected.hoursForShowing[4] === true) {
        resultString += '04:00 - 05:00 <br>';
      }
      if (this.schedule.injected.hoursForShowing[5] === true) {
        resultString += '05:00 - 06:00 <br>';
      }
      if (this.schedule.injected.hoursForShowing[6] === true) {
        resultString += '06:00 - 07:00 <br>';
      }
      if (this.schedule.injected.hoursForShowing[7] === true) {
        resultString += '07:00 - 08:00 <br>';
      }
      if (this.schedule.injected.hoursForShowing[8] === true) {
        resultString += '08:00 - 09:00 <br>';
      }
      if (this.schedule.injected.hoursForShowing[9] === true) {
        resultString += '09:00 - 10:00 <br>';
      }
      if (this.schedule.injected.hoursForShowing[10] === true) {
        resultString += '10:00 - 11:00 <br>';
      }
      if (this.schedule.injected.hoursForShowing[11] === true) {
        resultString += '11:00 - 12:00 <br>';
      }
      if (this.schedule.injected.hoursForShowing[12] === true) {
        resultString += '12:00 - 13:00 <br>';
      }
      if (this.schedule.injected.hoursForShowing[13] === true) {
        resultString += '13:00 - 14:00 <br>';
      }
      if (this.schedule.injected.hoursForShowing[14] === true) {
        resultString += '14:00 - 15:00 <br>';
      }
      if (this.schedule.injected.hoursForShowing[15] === true) {
        resultString += '15:00 - 16:00 <br>';
      }
      if (this.schedule.injected.hoursForShowing[16] === true) {
        resultString += '16:00 - 17:00 <br>';
      }
      if (this.schedule.injected.hoursForShowing[17] === true) {
        resultString += '17:00 - 18:00 <br>';
      }
      if (this.schedule.injected.hoursForShowing[18] === true) {
        resultString += '18:00 - 19:00 <br>';
      }
      if (this.schedule.injected.hoursForShowing[19] === true) {
        resultString += '19:00 - 20:00 <br>';
      }
      if (this.schedule.injected.hoursForShowing[20] === true) {
        resultString += '20:00 - 21:00 <br>';
      }
      if (this.schedule.injected.hoursForShowing[21] === true) {
        resultString += '21:00 - 22:00 <br>';
      }
      if (this.schedule.injected.hoursForShowing[22] === true) {
        resultString += '22:00 - 23:00 <br>';
      }
      if (this.schedule.injected.hoursForShowing[23] === true) {
        resultString += '23:00 - 24:00 <br>';
      }

      return resultString;
    }
  },
  methods: {
    clickOnPageName() {
      this.$emit('clickOnPageName', this.schedule.page_id);
    },
    toggleShowDetails() {
      this.showDetails = !this.showDetails;
    },
    toggleShowHours() {
      this.showHours = !this.showHours;
    }
  },
  filters: {
    moment(value) {
      if (value) {
        return moment(value * 1000).format(' D MMM YYYY, H:mm ');
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.days-of-week div {
  text-align: center;
  border-radius: 6px;
  margin: 3px;
  height: 2em;
  width: 2em;
  background: #dddddd;
  color: #939393;
  transition: background 0.3s ease-in-out, color 0.3s ease-in-out;
}
.days-of-week div:first-child {
  /*margin-left: 0px;*/
}

.days-of-week div.enabled {
  background: #4dbd74;
  color: #ffffff;
  transition: background 0.3s ease-in-out, color 0.3s ease-in-out;
}
.hours-of-day {
  max-width: 20em;
}
.hours-of-day div {
  text-align: center;
  border-radius: 6px;
  margin: 3px;
  height: 2em;
  width: 2em;
  background: #dddddd;
  color: #939393;
  transition: background 0.3s ease-in-out, color 0.3s ease-in-out;
}

.hours-of-day div.enabled {
  background: #4dbd74;
  color: #ffffff;
  transition: background 0.3s ease-in-out, color 0.3s ease-in-out;
}
.icon-small-in-pages-schedule {
  font-size: 0.7em !important;
}
.card-block-in-pages-schedule {
  margin-bottom: 0 !important;
  margin-top: 0 !important;
  padding-top: 0.2em !important;
  padding-bottom: 0.2em !important;
}
.page-showing-status {
  position: absolute;
  top: 2px;
  right: 4px;
}
.page-showing--active-and-day-match {
  color: #4dbd74;
}

.page-showing--planned {
  color: #f8cb00;
}

.page-showing--active-but-day-not-match {
  color: #939393;
}

.page-schedule-range {
  font-size: 0.8em;
}

.page-item-in-schedule-list {
  margin-bottom: 0.5rem;
}

.page-item-in-schedule-list div {
  padding-top: 1px;
  padding-bottom: 3px;
}
.break-all {
  word-break: break-all;
}
</style>
