<template>
  <div class="pb-2">
    <div class="card-container new-modern-style-data-iterator-card" @click="$emit('card-click')">
      <div class="header-container" :id="title">
        <slot name="checkbox"></slot>
        <h6 class="header d-flex justify-content-between my-0 mr-q">
          {{ title }}
        </h6>
        <slot name="header-icons"></slot>
        <!-- <h6>
          <span class="badge badge-default" :class="{ 'badge-success': status === 'active' }">
            {{ localizedStatus }}
          </span>
        </h6> -->
      </div>
      <div class="body-container" :class="{ 'justify-content-start': justifyContentStart }">
        <slot name="card-body" />
      </div>
      <div class="footer-container">
        <slot name="card-footer" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'DataIteratorCard',
  props: {
    title: { type: String, default: '' },
    status: { type: String, default: '' },
    justifyContentStart: { type: Boolean, default: false }
  },
  computed: {
    localizedStatus() {
      return this.$t(`adsprofile.${this.status}Ad`);
    }
  }
};
</script>
<style lang="scss" scoped>
.header {
  width: 100%;
}
.header-container {
  display: flex;
  padding: 0.5rem 0 0.5rem 0.5rem;
  border-bottom: 1px solid #e3e8ec;
  word-break: break-all;
}
.card-container {
  display: flex;
  flex-flow: column nowrap;
  background-color: #ffffff;
  height: 100%;
  min-height: 320px;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.card-container:hover {
  // background-color: #f2f3f5;
  // transition: background-color 0.4s ease-in-out;
  // cursor: pointer;
}
.body-container {
  display: flex;
  flex-flow: column nowrap;
  flex-grow: 1;
  justify-content: space-evenly;
  padding: 0.5rem;
  // max-height: 350px;
}

.justify-content-start {
  justify-content: flex-start !important;
}
</style>
